import { styled, createTheme } from '@mui/material/styles';
import {darken, lighten, alpha} from "@mui/material";
import { deepmerge } from '@mui/utils';
import * as Bs4Utils from '../Bs4Components/Bs4Utils'

const colorPrimary  = '#022C57';

export const baseTheme = {    
  palette: {
    //mode: 'dark',
    primary: {      
      main: colorPrimary,
      //light: lighten(colorPrimary, 0.4),
      //dark: darken(colorPrimary, 0.4),      
    },
  },  
  typography: {
    button: {
      textTransform: 'none'
    },
    // bs4xx: {
    //   fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
    //   fontWeight: 300,
    //   fontSize: "6rem",
    //   lineHeight: 1.167,
    //   letterSpacing: "-0.01562em"
    // },
  },
  components: {
    MuiButtonGroup: {
      defaultProps: {        
        //variant: 'text',
        //variant: 'outlined',
        variant: 'contained',
        size: "small",
      },     
    },
    MuiButton: {
      defaultProps: {
        //variant: 'text',
        //variant: 'outlined',
        variant: 'contained',
        size: 'small',
      },
      variants: [{
          props: { size: "topMenu" },
          style: { padding: "0px 6px", },
        }, {
          props: { size: "extraSmall" },
          style: { padding: "0px 0px", },
        }
      ]
    },
    MuiToggleButtonGroup:{
      defaultProps: {        
        size: "small",
      },     
    },
    MuiToggleButton: {
      defaultProps: {        
        size: 'small',
      },
    },
    MuiIconButton: {
      defaultProps: {        
        size: 'small',
      },
    },
    MuiTextField: {
      defaultProps: {
        //variant: 'filled',
        //variant: 'outlined',
        variant: 'standard',
        size: "small",
        margin: 'dense', // 'dense', 'normal', 'none'        
      },      
    },
    MuiFormControl: {
      defaultProps: {
        //variant: 'filled',
        //variant: 'outlined',
        variant: 'standard',
        size: "small",
        margin: 'dense', // 'dense', 'normal', 'none'
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: "small",
      }
    },
    MuiSelect: {
      defaultProps: {
        size: "small",
        variant: 'standard',
        margin: 'dense', // 'dense', 'normal', 'none'
      }
    },
    MuiChip: {
      defaultProps: {
        size: 'small',
        variant: 'filled',
        //variant: 'outlined'
      }
    }
  }
};

export default function getBs4Theme () {  
  const theme = createTheme(baseTheme);
  return theme;
}
