import Bs4MainDispatch from './Bs4MainDispatch';

export default class Bs4I18N {
  static BS4I18N_TOPHRASE = 'Bs4I18N_ToPhrase'
  static toPhrase = {};
  static okPhrase = {};
  static noPhrase = {};

  static bs4ConstructorMode = false;

  static restoreToPhrase() {    
    try {
      Bs4I18N.setToPhrase(JSON.parse(window.localStorage.getItem(Bs4I18N.BS4I18N_TOPHRASE)));
    } catch (error) {}
  }

  static setToPhrase(value) {    
    //console.log('... Bs4I18N.setToPhrase ...', value)
    if (value && typeof value === 'object' && !Array.isArray(value) && !(typeof value === 'string' || value instanceof String)) {
      Bs4I18N.toPhrase = value;
      Bs4I18N.okPhrase = {};
      for (let [key, value] of Object.entries(Bs4I18N.noPhrase)) {
        if (key in Bs4I18N.toPhrase) delete Bs4I18N.noPhrase[key];
      }
    }
    window.localStorage.setItem(Bs4I18N.BS4I18N_TOPHRASE, JSON.stringify(Bs4I18N.toPhrase));
  }

  static getTT(key, suggested, comment) {
    const value = Bs4I18N.toPhrase[key]
    if (!value) {
      if (key in Bs4I18N.noPhrase) Bs4I18N.noPhrase[key].count += 1;
      else Bs4I18N.noPhrase[key] = {suggested: suggested, count: 1, comment: comment};
      //return translated || key;
      return key;
      //return key+' <)';
    }
    if (key in Bs4I18N.okPhrase) Bs4I18N.okPhrase[key].count += 1;
      else Bs4I18N.okPhrase[key] = {translated: value, count: 1, comment: comment};
    return value;
  }

  //TYLKO W TRYBIE KONSTRUKTOR

  static listnoPhrase() {                
    console.log('=== No phrase:', Object.keys(Bs4I18N.noPhrase));
    console.log('=== To phrase:', Bs4I18N.toPhrase);    
    console.log('=== statistics no phrase:', Bs4I18N.noPhrase);
    console.log('=== statistics to phrase:', Bs4I18N.okPhrase);

    const noPhrase = Object.keys(Bs4I18N.noPhrase);
    if (Bs4I18N.bs4ConstructorMode && noPhrase.length > 0) {      
      const locale = String(navigator.language).replace('-', '_');
      Bs4MainDispatch.commandParser('bs4LocaleList', {locale: locale, operation: 2, phrases: noPhrase});
    }
  }
}