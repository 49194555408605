import { bs4RunToast } from './Bs4Toast'
import Bs4Login, { loginApi } from './Bs4Login'
import {EventEmit} from './Bs4Components/Bs4Utils'
import * as Bs4Utils from './Bs4Components/Bs4Utils'
import Bs4I18N from './Bs4I18N';

window.bslink = (cmd, details) => {
  Bs4MainDispatch.commandParserWait = {cmd, details };
  console.log('=== bslink ===', cmd, details)
}

export default class Bs4MainDispatch {  
  
  static commandParserWait = null;
  static lastCommandParserAfterRow = null;
  static eventEmit = new EventEmit();

  static on(name, onFunction) {
    this.eventEmit.on(name, onFunction);  
  }

  static off(onFunction) {
    this.eventEmit.off(onFunction);  
  }

  static trigger(name, ...params) {
    this.eventEmit?.trigger(name, ...params)
  }

  static getUrl = () => {     
    if (String(window.location.host).startsWith("localhost:300")) {
      console.log('...URL...', window.location.host)
      //return 'bs4/bs4core_jw2/bs4/';
      // return 'bs4/loginpagecompiled/';
      //return '/wersje/bs4core/jw2/bs4/';
      return 'bs4/bs4server/translate/';
      //return devPathName;
      //return 'wersje/bs4core/jw/bs4/'
      //return 'wersje/bs4core/wzorcowa/bs4/'
      //<1>//return 'hq/bs4/'      
      //return 'wersje/bs4core/jw2/bs4/'
      //return 'wersje/bs4core/lm/bs4/'
      //return '/hq/bs4/'
      //return 'wersje/bs4core/manual_2/bs4/'
      //https://dev.bs4.eu/wersje/bs4core/manual_2/bs4/index.php
    }

    let pathname = window.location.pathname;
    if (! pathname.endsWith('/')) pathname = pathname.substring(0, pathname.lastIndexOf('/')) + "/";
    //console.log('... window.location ...', window.location, '...bs4.prod.pathname...:',pathname);
    return pathname
  }

  static getUrlPath = (path) => {
    let url = null;
    if (Bs4Utils.isString(path) && path.startsWith('http')) url =  path;
    else url = Bs4MainDispatch.getUrl()+path;
    //console.log('=== URL ===', url)
    return url;
  }

  static getParserParams(data) {
    data.additionalget = Bs4MainDispatch.additionalget;
    return data;
  }

  static commandParserAfter() {
    if (Bs4MainDispatch.commandParserWait) {       
      const commandParserWait = Bs4MainDispatch.commandParserWait
      Bs4MainDispatch.commandParserWait = null;      
      Bs4MainDispatch.commandParser(commandParserWait.cmd, commandParserWait.details);
      return true;
    }
    return false;
  }    

  static startTime = (() => Bs4MainDispatch.setStartTime ())();

  static setStartTime () { 
    // zewnetrzne linki z push
    const urlParams = new URLSearchParams(window.location.search);
    Bs4MainDispatch.additionalget = {};
    for (const [key, value] of urlParams.entries()) {
      Bs4MainDispatch.additionalget[key] = value;
    }

    Bs4MainDispatch.startTime = new Date();
    const locale = String(navigator.language).replace('-', '_');
    Bs4MainDispatch.commandParser('bs4LocaleList', {locale: locale});
    return Bs4MainDispatch.startTime;
  }

  static commandParser (com, data) {    
    const startTime = new Date();
    data = Bs4MainDispatch.getParserParams(data);
    console.log('=== commandParser ', com, data);
    const formData = new FormData();
    formData.append('allparams', JSON.stringify(data));
    
    fetch(Bs4MainDispatch.getUrlPath(`web.php?com=${com}`), {method: 'POST', body: formData})
    .then(response => response.text())
    .then(returnedText => {

      if (Bs4MainDispatch.startTime > startTime) {
        console.log('=== deprecated request ===', com, data, 'start fetch - start program =', startTime - Bs4MainDispatch.startTime)
        return;
      }
  
      try {
        const returnedJson = JSON.parse(returnedText);
        const lastCommandParserAfterRow = Bs4MainDispatch.lastCommandParserAfterRow;
        Bs4MainDispatch.lastCommandParserAfterRow = null;
        Bs4MainDispatch.parsingBs4Response(returnedJson);
      } catch (e) {        
        console.log(returnedText, com, data);
        console.log(e);
      }
    })
    .catch((error) => {
      console.log('=== fatch catch');
      console.error(error);
    });
  }

  static parsingBs4Response(returned, bs4KartotekaId, lastCommandParserAfterRow, forceRegion) {
    const messgs = returned?.messgs;
    if (Array.isArray(messgs) && messgs.length) {
      Bs4MainDispatch.parsingBs4ResponseMessgs(messgs, bs4KartotekaId, lastCommandParserAfterRow, forceRegion);
    } else {
      //todo error
    }
  }

  static parsingBs4ResponseMessgs (messages, bs4KartotekaId, lastCommandParserAfterRow, forceRegion) {
    if (!messages) return;    

    if (!Array.isArray(messages)) messages = [messages]

    Bs4MainDispatch.toBeContinue = null;

    if (!messages.length) return;  

    const message = messages.shift();
    message.forceRegion = forceRegion;

    //console.log('%c=== cmd === %s', 'color: yellow; background-color: darkred;', message._Typ);
    console.log('%c %s ', 'color: yellow; background-color: darkred; font-size: 16px', message._Typ);
    console.log({...message});


    switch (message._Typ) {
      case 'LOGINOK':
        loginApi.afterLogin(message);        
        break;
        
      case 'OPEN_NEW_TAB_LOCAL':
        window.open(Bs4MainDispatch.getUrlPath(`${message._Komunikat.toString()}`));
        break;

      case 'OPEN_NEW_TAB_URL':
        //window.open(message._Komunikat.toString());
        window.open(Bs4MainDispatch.getUrlPath(`${message._Komunikat.toString()}`));
        break;
        
      case 'REDIR_FULL_WINDOW':
        window.location.href = message._Komunikat;
        break;

      case 'CHANGES_SAVED':
        this.trigger('CHANGES_SAVED', message);
        break;

      case 'LOCALELIST':
        Bs4I18N.setToPhrase(message._Komunikat);
        break;      

      case 'ASK_CODE_STEP2':
        loginApi.setAskCodeStep2(message);
        break;

      case 'ASK_SYSTEM_STEP2':
        loginApi.setAskSystemStep2(message);
        break;
        
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
        console.log('=== 0 1 2 3 4 ===', message)
        const msg = <div>{message._Komunikat.toString()}</div>
        if(message._Komunikat) bs4RunToast(message._Komunikat.toString(), message._Typ, bs4KartotekaId);
        break;


      default:      
        console.log('=== !!! type error === ', message._Typ);
        break;
    }

    Bs4MainDispatch.parsingBs4ResponseMessgs(messages);
  }
}