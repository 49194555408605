import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';

import Bs4MainDispatch from './Bs4MainDispatch';
import Bs4I18N from './Bs4I18N';

import loginGoogle from './Bs4LoginImages/btn_google_signin_light_normal_web.png'
import loginMicrosoft from './Bs4LoginImages/ms-symbollockup_signin_light.png'

import { setBs4Toast } from './Bs4Toast';
import { useSnackbar } from 'notistack'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props}/>;
});

const loginType = String(window.location.hash).replace('#', ''); 

export const loginApi = {};

export default function Bs4Login(props) {    
  const [login, setLogin] = React.useState(props?.login?.currentUser || '');
  const [password, setPassword] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);  
  const loginTitle = Bs4I18N.getTT("Login to bs4", 'Logowanie do bs4', 'Bs4Login');
  const loginEnter = Bs4I18N.getTT("Enter", 'Wejdź', 'Bs4Login');
  const chooseCancel = Bs4I18N.getTT("Cancel", 'Anuluj', 'Bs4Login');
  const loginSend = Bs4I18N.getTT("Send", 'Wyślij', 'Bs4Login');  
  const forgotPassword = Bs4I18N.getTT("I forgot my password", '?', 'Bs4Login');
  // const sessionExpired = Bs4I18N.getTT("Session expired. Log in again", 'Sesja wygasła. Zaloguj się ponownie', 'Bs4Login');
  const enterEmail = Bs4I18N.getTT("Enter your email address", 'Wpisz swój adres e-mail', 'Bs4Login');
  const enterOneTimeCode = Bs4I18N.getTT("Enter the one-time code from the Authenticator app", 'Wprowadź kod jednorazowy z aplikacji Authenticator', 'Bs4Login');
  const enterOneTimeCode2 = Bs4I18N.getTT(
    'Aplikację mobilną bs4 trzeba powiązać z odpowiednim kontem w bs4. Jeśli nie masz kodu zaproszenia, zaloguj się do bs4 core najpierw przez przeglądarkę.', 
    'Aplikację mobilną bs4 trzeba powiązać z odpowiednim kontem w bs4. Jeśli nie masz kodu zaproszenia, zaloguj się do bs4 core najpierw przez przeglądarkę.'
    );
  const selectYourSystem = Bs4I18N.getTT("Please select your system", 'Wybierz swój system', 'Bs4Login');
  
  const continueNext = Bs4I18N.getTT("Continue", 'Dalej', 'Bs4Login');
  
  const [openGetEmail, setOpenGetEmail] = React.useState(false);    
  const [newEmail, setNewEmail] = React.useState('');
  const [newOneTimeCode, setOneTimeCode] = React.useState('');
  const [askCodeStep2, setAskCodeStep2] = React.useState(null);
  const [askSystemStep2, setAskSystemStep2] = React.useState(null);
  const theme = useTheme();

  const bs4UseSnackbar = useSnackbar()    
  setBs4Toast(bs4UseSnackbar)

  React.useEffect(() => {    
    // zewnetrzne linki z push
    const urlParams = new URLSearchParams(window.location.search);
    Bs4MainDispatch.additionalget = {};
    for (const [key, value] of urlParams.entries()) {
      Bs4MainDispatch.additionalget[key] = value;
    }
    
    document.bs4Login = true;
    document.title = loginTitle;

    loginApi.afterLogin = (message) => {
      //window.localStorage.setItem(BS4SESSION, SESSION_LOGGED)
      //this.setLogin(null);
      //const currentUser = window.localStorage.getItem(SESSION_EXPIRED_MESSAGE);
      //if (Boolean(currentUser)) return;
      console.log('...Login...afterLogin...', message)
    }    
    
    loginApi.setAskCodeStep2 = (message) => {
      console.log('...Login...setAskCodeStep2...', message)
      setAskCodeStep2(message._Komunikat);
    }

    loginApi.setAskSystemStep2 = (message) => {
      console.log('...Login...setAskSystemStep2...', message)
      const a = message._Komunikat;
      setAskSystemStep2(message._Komunikat);
    }

    const closeDialogs = () => {
      setAskCodeStep2(false);
      setAskSystemStep2(false);
      setOpenGetEmail(false)
      setOneTimeCode('');
      setNewEmail('');
    }

    Bs4MainDispatch.off('CHANGES_SAVED', closeDialogs);
    Bs4MainDispatch.on('CHANGES_SAVED', closeDialogs);

    return () => {
      document.title = document.bs4Title || 'bs4 core';
      delete document.bs4Login;
      Bs4MainDispatch.off('CHANGES_SAVED', closeDialogs);
    }
    
  }, [])

  const handleChangeLogin = (event) => {
    setLogin(event.target.value);
  }

  const handleChangeePassword = (event) => {
    setPassword(event.target.value);
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePowerOff = (e) => {
    Bs4MainDispatch.commandParser('Bs4NaviElement', { navElemId: 160});
  }
  
  const onKeyDownPassword = (e) => {
    if (e.keyCode === 13) handleClickLogin()
  }

  const handleClickLogin = () => {
    const comCore = 'Bs4AjaxLogin';
    const comEbok = 'Bs4AjaxEbokLogin';
    const loginType = String(window.location.hash).replace('#', '');
    const com = loginType === 'ebok' ? comEbok : comCore;
    const formData = new FormData();
    formData.append('login', login);
    formData.append('password', password);
    fetch(Bs4MainDispatch.getUrlPath(`web.php?com=${com}`), {method: 'POST', body: formData})
    .then(response => {
      return response.text();
    })
    .then(returnedText => {
      try {
        const returnedJson = JSON.parse(returnedText);
        Bs4MainDispatch.parsingBs4Response(returnedJson, null);
      } catch (e) {        
        console.log(returnedText, com);
        console.log(e);
      }
    })
    .catch((error) => {
      console.log('=== fatch catch');
      console.log(error);
    });
  }

  const handleForgotPassword = (event) => {
    setNewEmail('')
    setOpenGetEmail(true);
  }

  const handleForgotPasswordOk = (event) => {
    Bs4MainDispatch.commandParser('bs4ForgotPass', { email: newEmail});    
  }
  
  const handleEnterOneTimeCodeKey = (event) => {
    if (event.key === 'Enter' && newOneTimeCode?.length >= 6) {
      handleEnterOneTimeCode(event)
    }
  }

  const handleEnterOneTimeCode = (event) => {
    Bs4MainDispatch.commandParser('bs4AfterLoginCode', { tempId: askCodeStep2, code: newOneTimeCode});
    setAskCodeStep2(false);
  }

  const handleLoginGoogle = (event) => {
    Bs4MainDispatch.commandParser('bs4PartnerLogin', { partner: 2});
  }

  const handleLoginMicrosoft = (event) => {
    Bs4MainDispatch.commandParser('bs4PartnerLogin', { partner: 3});
  }
    
  const handleAskCodeStep2Click = (item) => {
    Bs4MainDispatch.commandParser('bs4AfterSystemSelection', { tempId: askSystemStep2.tempId, code: item.id});
    setAskSystemStep2(false);    
  }

  const handleCloseAskCodeStep2 = (event, reason) => {
    if (reason === "backdropClick") return;
    setAskCodeStep2(false)
  }

  const handleCloseAskSystemStep2 = (event, reason) => {
    if (reason === "backdropClick") return;
    setAskSystemStep2(false);
  }

  const imgTop = loginType === 'ebok' ? 'logineboklogo' : 'loginlogo';
  const imgLeft = loginType === 'ebok' ? 'loginebokscreen' : 'loginscreen';
  
  const linkTop = Bs4MainDispatch.getUrlPath(`showp.php?${imgTop}`);
  const linkLeft = Bs4MainDispatch.getUrlPath(`showp.php?${imgLeft}`);

  //console.log('... Login ...', askCodeStep2, askSystemStep2)

  return (
    <Dialog fullScreen open={true} TransitionComponent={Transition}>      
      {/* <DialogContent> */}
        <Box 
          height='100%' 
          display='flex' 
          flexDirection='row' 
          justifyContent='center' 
          alignItems='center' 
          flexWrap='wrap'
        >
          <Box 
            //height='100%' 
            height={{ xs: 'auto', sm: 'auto', md: 'auto', lg: '100%'}}
            //width='50%' 
            width={{ xs: '100%', sm: '100%', md: '100%', lg: '50%'}} 
            display='flex' 
            flexDirection='column' 
            //justifyContent='flex-start' 
            //alignItems='flex-start'
            justifyContent='center' 
            alignItems='center'
          >
            <DialogTitle>
              {/* {Boolean(props?.login?.currentUser) ? sessionExpired : null}  */}
            </DialogTitle> 
            <Box 
              flex={1} 
              //width='100%' 
              //width={{ xs: 'auto', sm: 'auto', md: 'auto', lg: '100%'}} 
              display='flex' 
              flexDirection='column' 
              justifyContent='center' 
              alignItems='center' 
              gap={3}
            >
              {/* <img src={linkTop} style={{height:'100px', border: "none"}}/> */}
              <img src={linkTop} style={{maxWidth: "400px", maxHeight: "100px", border: "none"}}/>              
              <Card sx={{boxShadow: {xs: 'none', sm: 'none', md: 'none', lg: theme.shadows[1]}}}>
                <CardHeader 
                  sx={{backgroundColor: '#fafafa'}}
                  //titleTypographyProps={{align:'center', backgroundColor: '#fafafa'}}
                  title={loginTitle}
                  action={
                    Boolean(props?.login?.currentUser)
                    ? 
                    <IconButton aria-label='power-off' size='medium' onClick={handlePowerOff}>
                      <i className="fas fa-power-off"></i>
                    </IconButton>
                    :
                    null
                  }
                />     
                <CardContent sx={{px: 2}}>
                  <TextField
                    id='bs4loginUser'
                    //variant='outlined'
                    fullWidth
                    disabled={Boolean(props?.login?.currentUser)}
                    label='Adres e-mail'
                    value={login}
                    onChange={handleChangeLogin}
                  />
                  <TextField         
                    id='bs4loginPassword'  
                    //variant='outlined'
                    fullWidth
                    label='Hasło'
                    value={password}
                    type={showPassword ? 'text' : 'password'}
                    onChange={handleChangeePassword}
                    onKeyDown={onKeyDownPassword}
                    InputProps={{
                      endAdornment:(
                        <InputAdornment position="end">
                          <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </CardContent>
                <CardActions sx={{px: 2}}>
                  <Stack direction="column" justifyContent="center" alignItems="center" sx={{width: '100%'}}>
                    <Button fullWidth onClick={handleClickLogin}>{loginEnter}</Button>
                    <Button variant='text' onClick={handleForgotPassword} sx={{m: 3}}>{forgotPassword}</Button>
                    <Stack  
                      //direction="row" 
                      direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row'}}
                      justifyContent="center" 
                      alignItems="center" 
                      sx={{py: 3}}
                    >
                      <ButtonBase onClick={handleLoginGoogle}><img src={loginGoogle}/></ButtonBase>     
                      <Box m={0.5}/>               
                      <ButtonBase onClick={handleLoginMicrosoft}><img src={loginMicrosoft}/></ButtonBase>
                    </Stack>
                  </Stack>
                </CardActions>
                <Dialog open={openGetEmail} onClose={() => setOpenGetEmail(false)} disableRestoreFocus>
                  <DialogTitle>{enterEmail}</DialogTitle>
                  <DialogContent>                  
                    <TextField 
                      fullWidth 
                      value={newEmail} 
                      onChange={(event) => setNewEmail(event.target.value)} 
                      error={!Boolean(newEmail)} 
                      helperText={!Boolean(newEmail) ? enterEmail : ''}
                      autoFocus
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpenGetEmail(false)}>{chooseCancel}</Button>
                    <Button onClick={handleForgotPasswordOk} disabled={!Boolean(newEmail)}>{loginSend}</Button>
                  </DialogActions>
                </Dialog>
                <Dialog open={Boolean(askCodeStep2 || true)} onClose={handleCloseAskCodeStep2} disableRestoreFocus maxWidth={'xs'}>
                  <DialogTitle>
                    <Typography variant='h6'>{enterOneTimeCode}</Typography>
                    <br/>
                    <Typography variant='subtitle2' sx={{textAlign: 'center'}}>{enterOneTimeCode2}</Typography>
                    {/* {enterOneTimeCode} */}
                  </DialogTitle>
                  <DialogContent>                  
                    <TextField 
                      fullWidth 
                      value={newOneTimeCode} 
                      onChange={(event) => setOneTimeCode(event.target.value)} 
                      autoFocus
                      onKeyDown={handleEnterOneTimeCodeKey}
                    />
                  </DialogContent>
                  <DialogActions>
                    {/* <Button onClick={() => setAskCodeStep2(false)}>{chooseCancel}</Button> */}
                    <Button onClick={handleEnterOneTimeCode} disabled={!Boolean(newOneTimeCode && newOneTimeCode?.length >= 6)}>{continueNext}</Button>
                  </DialogActions>
                </Dialog>
                <Dialog open={Boolean(askSystemStep2)} onClose={handleCloseAskSystemStep2} disableRestoreFocus>
                  <DialogTitle>{selectYourSystem}</DialogTitle>
                  <DialogContent>                  
                    <List>
                      {(askSystemStep2?.list || []).map(item => (
                          <ListItem key={item.id} disablePadding>
                            <ListItemButton onClick={() => handleAskCodeStep2Click(item)}>
                              <ListItemText primary={item.name} />
                            </ListItemButton>
                          </ListItem>
                      ))}                      
                    </List>
                  </DialogContent>
                  {/* <DialogActions>
                    <Button onClick={() => setAskCodeStep2(false)}>{chooseCancel}</Button>
                  </DialogActions> */}
                </Dialog>                
              </Card>
            </Box>
            {/* <Box width='100%' p={2}>
              <img src="bs4_core.png" />
            </Box> */}
          </Box>
          <Box 
            height={{ xs: 'auto', sm: 'auto', md: 'auto', lg: '100%'}}
            width={{ xs: '100%', sm: '100%', md: '100%', lg: '50%'}} 
            display='flex' 
            flexDirection='column' 
            justifyContent='center' 
            alignItems='center' 
            p={0}
          >
            <img src={linkLeft} style={{width:'100%', height:'100%', objectFit:'cover', objectPosition: 'center'}}/>
          </Box>
        </Box>
      {/* </DialogContent>           */}
    </Dialog>
  );
}
