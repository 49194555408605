import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useSnackbar, SnackbarContent } from 'notistack';
import Bs4MainDispatch from './Bs4MainDispatch';



const multiplier = 1;
const bs4ToastVatiants = {
  0:  {autoHideDuration: multiplier * 3000,   variant: 'success'},
  1:  {autoHideDuration: null,                variant: 'error'},
  2:  {autoHideDuration: multiplier * 2*3000, variant: 'warning'},
  3:  {autoHideDuration: null,                variant: 'error'},
  4:  {autoHideDuration: multiplier * 3000,   variant: 'success'},
  11: {autoHideDuration: multiplier * 3000,   variant: 'info'},
  12: {autoHideDuration: multiplier * 3000,   variant: 'default'},
  21: {autoHideDuration: null,                variant: 'error', persist: true},
}

let snackbarCloseAllId = null;
const enqueueSnackbarIds = new Set();

export const VariantBs4 = React.forwardRef((props, ref) => {
  const theme = useTheme();

  let {backgroundColor, color, icon} = React.useMemo(() => {
    switch (props.variant) {
      case 'success': return {backgroundColor: theme.palette.success.main, color: theme.palette.success.contrastText, icon: "fa-solid fa-circle-check"};
      case 'error': return {backgroundColor: theme.palette.error.main, color: theme.palette.error.contrastText, icon: "fa-solid fa-circle-exclamation"};
      case 'warning': return {backgroundColor: theme.palette.warning.main, color: theme.palette.warning.contrastText, icon: "fa-solid fa-triangle-exclamation"};
      case 'info': return {backgroundColor: theme.palette.info.main, color: theme.palette.info.contrastText, icon: "fa-solid fa-circle-info"};
      default: return {backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText, icon: null};
    }
  }, [theme, props.variant]);

  const handleDismiss = React.useCallback(() => {bs4CloseToast(props.id)}, [props.id]);
  const handleDismissAll = React.useCallback(() => {enqueueSnackbarIds.forEach(id => bs4CloseToast(id))}, [props.id]);  

  const handleClick = () => {
    // console.log('...Toast click...', props)
    Bs4MainDispatch.commandParserAfter(props.bs4KartotekaId);
  }

  if (props.variant === 'closeAll') return (
    <SnackbarContent ref={ref} style={{minWidth:'2px'}}>
      <Stack sx={{width: '100%'}} direction="row" justifyContent="space-between" alignItems="center">
        <Box/>
        <Stack 
          sx={{
            backgroundColor: backgroundColor, 
            color: color,
            borderRadius: theme.shape.borderRadius+'px',
            padding: '8px',
            boxShadow: theme.shadows[3],
          }} 
          direction="row" 
          justifyContent="space-between" 
          alignItems="center"
          spacing={1}
        >        
          <IconButton size="small" onClick={handleDismissAll} sx={{color: 'inherit'}}>
            <i className="fa-solid fa-xmark"></i>
          </IconButton>
        </Stack>      
      </Stack>
    </SnackbarContent>
  );

  return (    
    <SnackbarContent ref={ref} style={{minWidth:'2px'}}>
      <Stack 
        sx={{
          width: '100%', 
          backgroundColor: backgroundColor, 
          color: color,
          borderRadius: theme.shape.borderRadius+'px',
          padding: '8px',
          boxShadow: theme.shadows[3],
        }}
        direction="row" 
        justifyContent="space-between" 
        alignItems="center"
        spacing={1}
      >
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <IconButton size="small" onClick={handleDismiss} sx={{color: 'inherit'}}>
            <i className={icon}></i>
          </IconButton>
          <div dangerouslySetInnerHTML={{__html:props.message}} onClick={handleClick}/>
        </Stack>
        <IconButton size="small" onClick={handleDismiss} sx={{color: 'inherit'}}>
          <i className="fa-solid fa-xmark"></i>
        </IconButton>
      </Stack>      
    </SnackbarContent>
  );
});

export let bs4Toast = null;
export const setBs4Toast = (newBs4Toast) => {
  bs4Toast = newBs4Toast;
}

export const bs4RunToast = (message, type, bs4KartotekaId) => {

  const handleClose = (e, reson, id) => {
    enqueueSnackbarIds.delete(id);
    if (enqueueSnackbarIds.size === 0 && snackbarCloseAllId) {
      const id = snackbarCloseAllId
      snackbarCloseAllId = null;
      bs4CloseToast(id);      
    }
  }

  if (bs4Toast) {
    const variant = bs4ToastVatiants[type] || bs4ToastVatiants[11]
    
    if (enqueueSnackbarIds.size === 0) {
      snackbarCloseAllId = bs4Toast.enqueueSnackbar('', {autoHideDuration: null, variant: 'closeAll', persist: true, onClose: handleClose, bs4KartotekaId: bs4KartotekaId});
    }

    const id = bs4Toast.enqueueSnackbar(message, {...variant, onClose: handleClose, bs4KartotekaId: bs4KartotekaId});
    enqueueSnackbarIds.add(id);
    return id;
  }
}

export const bs4CloseToast = (id) => {
  if (bs4Toast) bs4Toast.closeSnackbar(id);
}

