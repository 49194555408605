import { alpha } from '@mui/material';
import * as React from 'react';

export const getValidDate = (date) => {
  if (!date) return null;
  const newDate = new Date(date);
  if (!(newDate instanceof Date) || isNaN(newDate)) return null;  
  return newDate;  
}

export const getValidDateFromTime = (time) => {
  if (!time) return null;
  const newDate = new Date('0001-01-01 '+time);
  if (!(newDate instanceof Date) || isNaN(newDate)) return null;  
  return newDate;  
}

export const toDateTimeStamp = (date) => {
  const newDate = getValidDate(date);
  if (!newDate) return '';
  let dateStr = new Date(newDate.getTime() - (newDate.getTimezoneOffset() * 60000 )).toISOString();
  dateStr = dateStr.replace('T', ' ');
  dateStr = dateStr.replace('Z', '');
  return dateStr;  
}

export const toDateTime = (date) => {
  const newDate = getValidDate(date);
  if (!newDate) return '';
  const dateStr = new Date(newDate.getTime() - (newDate.getTimezoneOffset() * 60000 )).toISOString().split("T");
  const timeStr = dateStr[1].split('.');
  return dateStr[0]+' '+timeStr[0];
}

export const toDate = (date) => {
  const newDate = getValidDate(date);
  if (!newDate) return '';
  const dateStr = new Date(newDate.getTime() - (newDate.getTimezoneOffset() * 60000 )).toISOString().split("T");
  return dateStr[0]
}

export const toTime = (date) => {
  const newDate = getValidDate(date);
  if (!newDate) return '';
  const dateStr = new Date(newDate.getTime() - (newDate.getTimezoneOffset() * 60000 )).toISOString().split("T");
  const timeStr = dateStr[1].split('.');
  return timeStr[0];
}

export const isString = (value) => {
  return (typeof value === 'string' || value instanceof String)
}

export const getString = (value, defoult) => {
  if (isString(value)) return value;
  return defoult;
}

export const getNumber = (value, defoult) => {
  if (isNaN(parseFloat(value))) return defoult;
  return value;
}

export const isObject = (value) => {
  return (value && typeof value === 'object' && !Array.isArray(value) && !isString(value));
}

export class EventEmit {
  constructor() {
    this.events = {}
  }

  on(type, cb) {
    if (this.events[type]) {
      this.events[type].push(cb);
    } else {
      this.events[type] = [cb];
    }
  }

  off(cb) {
    Object.keys(this.events).forEach((key) => {
      this.events[key] = this.events[key].filter(eventListener => eventListener !== cb)
    });
  }

  trigger(type, ...args) {
    if (this.events[type]) {
      this.events[type].forEach(eventListener => {
        eventListener(...args);
      });
    } else {
      //console.log('== WARNING EventEmit trigger == no type:', type);
    }
  }
}

export const getLastZIndex = (e) => {      
  if (!e?.parentNode) return 0;
  let z = parseInt(window.getComputedStyle(e).getPropertyValue('z-index'));
  z = isNaN(z) ? 0 : z
  let zp = parseInt(getLastZIndex(e.parentNode));
  zp = isNaN(zp) ? 0 : zp
  return z + zp; 
}

export const dragElement = (elmnt, header) => {
  if (!elmnt) { 
    return;
  }

  let startX = 0, startY = 0;
  let moveX = 0, moveY = 0

  if (header) {
    /* if present, the header is where you move the DIV from:*/
    header.onmousedown = dragMouseDown;
  } else {
    /* otherwise, move the DIV from anywhere inside the DIV:*/
    elmnt.onmousedown = dragMouseDown;
  }

  function dragMouseDown(e) {
    e = e || window.event;    

    const rect = e.currentTarget.getBoundingClientRect();
    if (e.clientY - rect.y > 30) return;

    e.preventDefault();
    // get the mouse cursor position at startup:
    startX = e.clientX;
    startY = e.clientY;    
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    moveX = e.clientX - startX;
    moveY = e.clientY - startY;
    startX = e.clientX;
    startY = e.clientY;
    // set the element's new position:
    elmnt.style.top = (elmnt.offsetTop + moveY) + "px";
    elmnt.style.left = (elmnt.offsetLeft + moveX) + "px";
    //console.log('... dragElement .Down.', e)
  }

  function closeDragElement() {
    /* stop moving when mouse button is released:*/
    document.onmouseup = null;
    document.onmousemove = null;
  }
}

export const useExternalScript = (url) => {
  let [state, setState] = React.useState(url ? "loading" : "idle");

  React.useEffect(() => {
    if (!url) {
      setState("idle");
      return;
     }

    let script = document.querySelector(`script[src="${url}"]`);

    const handleScript = (e) => {
      setState(e.type === "load" ? "ready" : "error");
    };

    if (!script) {
      script = document.createElement("script");
      script.type = "application/javascript";
      script.src = url;
      script.async = true;
      document.body.appendChild(script);
      script.addEventListener("load", handleScript);
      script.addEventListener("error", handleScript);
    }

   script.addEventListener("load", handleScript);
   script.addEventListener("error", handleScript);

   return () => {
     script.removeEventListener("load", handleScript);
     script.removeEventListener("error", handleScript);
   };
  }, [url]);

  return state;
};

export const loadExternalScript = (url, handleScriptCaller) => {
  if (!url) return;

  const handleScript = (e) => {
    script.removeEventListener("load", handleScript);
    script.removeEventListener("error", handleScript);
    handleScriptCaller(e);
  }

  let script = document.querySelector(`script[src="${url}"]`);

  if (!script) {    
    script = document.createElement("script");    
    script.type = "application/javascript";
    script.src = url;
    script.async = true;
    document.body.appendChild(script);    
  }

   script.addEventListener("load", handleScript);
   script.addEventListener("error", handleScript);
};

export const getBs4Style = (bs4StyleStr, empty) => {
  if (bs4StyleStr) {
    try {
      let bs4Style = JSON.parse(bs4StyleStr)
      if (bs4Style && typeof bs4Style === 'object' && !Array.isArray(bs4Style)) return bs4Style;
    } catch(error) {
      return empty || null;
    }
  }
  return empty || null;
}

export const colorToRgba = (color) => {
  let colorRgba = null;
  let cccc = null;
  try {
    color = color.trim().toLocaleLowerCase()
    if (color.startsWith('#')) {
      if (color.length === 4) {
        colorRgba = {
          r: parseInt(color.slice(1, 2), 16) * 17,
          g: parseInt(color.slice(2, 3), 16) * 17,
          b: parseInt(color.slice(3, 4), 16) * 17,
          a: 1,
        }  
      } else if (color.length === 7) {
        colorRgba = {
          r: parseInt(color.slice(1, 3), 16),
          g: parseInt(color.slice(3, 5), 16),
          b: parseInt(color.slice(5, 7), 16),
          a: 1,
        }
      } else if (color.length === 9) {
        colorRgba = {
          r: parseInt(color.slice(1, 3), 16),
          g: parseInt(color.slice(3, 5), 16),
          b: parseInt(color.slice(5, 7), 16),
          a: parseFloat(parseInt(color.slice(7, 9), 16) / 255),
        }
      } else {
        throw new Error('Incorrect color format');
      }
    } else if (color.startsWith('rgb')) {
      cccc = color.replace('rgb', '').replace('(', '').replace(')', '').split(',');
      if (cccc.length === 3) colorRgba = {
        r: parseInt(cccc[0]),
        g: parseInt(cccc[1]),
        b: parseInt(cccc[2]),
        a: 1,
      }
    } else if (color.startsWith('rgba')) {
      cccc = color.replace('rgba', '').replace('(', '').replace(')', '').split(',');
      if (cccc.length === 4) colorRgba = {
        r: parseInt(cccc[0]),
        g: parseInt(cccc[1]),
        b: parseInt(cccc[2]),
        a: parseFloat(cccc[3]),
      }
    } else {
      throw new Error('Incorrect color format');
    }
  } catch (error) {
    //console.log('=== Error === colorToRgba ===', color, error);
    return null
  }  

  return colorRgba
}