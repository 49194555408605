import * as React from 'react';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import './App.css';
import { SnackbarProvider } from 'notistack';
import { VariantBs4 } from './bs4/Bs4Toast';
import Bs4Login from "./bs4/Bs4Login";
import getBs4Theme from './bs4/Bs4Theme/Bs4Theme';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function App(props) {
  const theme = useTheme();
  const bs4Theme = React.useMemo(() => {
    const bs4Theme = getBs4Theme();
    console.log('...Bs4Theme...', bs4Theme)
    return bs4Theme;
  }, []);  
  
  return (    
    <ThemeProvider theme={bs4Theme}>
      <SnackbarProvider 
        anchorOrigin={{vertical: 'top', horizontal: 'right',}} 
        maxSnack={20} 
        Components={{
          default: VariantBs4, 
          success: VariantBs4, 
          error: VariantBs4, 
          warning: VariantBs4, 
          info: VariantBs4,
          closeAll: VariantBs4,
          }}>        
        <Bs4Login/>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
